import Image from "next/legacy/image"
import { useState } from "react"
import { useTranslation } from "next-i18next"
import { Button, Grid, Typography, useMediaQuery, useTheme, Box, ButtonGroup } from "@mui/material"
import ChevronLeftIcon from "mdi-material-ui/ChevronLeft"
import ChevronRigthIcon from "mdi-material-ui/ChevronRight"
import { PhotoGrid, ServiceContent, ServiceTitle } from "./Services.styles"
import { ResponsiveYTVideo } from "src/app/core/components/ResponsiveYTVideo"

const content = [
  "homeSectionServicePhotos",
  "homeSectionServiceCalendar",
  "homeSectionServiceDayAtSchool",
  "homeSectionServiceVideo",
  "homeSectionServicePlatform",
]

const std = [
  { file: "dd730884-b272-43a0-1d98-1971c585ab01.jpg", direction: "portrait" },
  { file: "f53b7785-d93d-4920-0020-bf2b5fdc6101.jpg", direction: "portrait" },
  { file: "60f93912-e791-44f5-e20b-68349c7b7c01.jpg", direction: "portrait" },
  { file: "ec711acb-c6ea-4024-f1ed-b96f04833f01.jpg", direction: "portrait" },
  { file: "42eff147-3f04-450c-dbfa-ed3b317e8401.jpg", direction: "portrait" },
  { file: "bdf3abf7-a674-44d4-606a-97b0fa75c001.jpg", direction: "portrait" },
  { file: "0b55ea77-d5a9-4d78-a67f-6383ba155c01.jpg", direction: "landscape" },
  { file: "2b139e08-d75b-4da6-e3fb-f64d8a4f4401.jpg", direction: "landscape" },
]
const ditl = [
  { file: "515aa6ee-5ae1-4167-2097-a3ee302a7001.jpg", direction: "portrait" },
  { file: "8b57cc39-1791-4380-b2ce-ae5cbfeb1301.jpg", direction: "portrait" },
  { file: "98d07064-3d16-441c-7b71-899ff654a301.jpg", direction: "portrait" },
  { file: "b678232c-cfbb-4013-e561-c5d326a75501.jpg", direction: "portrait" },
  { file: "cce833ca-253d-4349-d865-4960f1ba3101.jpg", direction: "landscape" },
  { file: "cf6b019f-fdd6-4150-2a78-ee505fc03c01.jpg", direction: "landscape" },
  { file: "f534c42c-8051-497d-af72-4547ab0e3101.jpg", direction: "landscape" },
  { file: "fc04cabe-a041-4646-6535-d8b6abbbac01.jpg", direction: "landscape" },
]

export const Services = () => {
  const { t } = useTranslation("website")
  const [activeSection, setActiveSection] = useState(0)
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const createHandleActiveSection = (newActiveSection) => () => {
    setActiveSection(newActiveSection)
  }

  const handleClickPrev = () => {
    setActiveSection((prevState) => (prevState === 0 ? content.length - 1 : prevState - 1))
  }
  const handleClickNext = () => {
    setActiveSection((prevState) => (prevState === content.length - 1 ? 0 : prevState + 1))
  }

  return (
    <>
      <Typography variant="h1" color="primary" textAlign="center" fontWeight={400} mb={8}>
        {t("homeSectionTitle")}
      </Typography>

      {isSmallScreen ? (
        <>
          <Box mb={4}>
            {content.map((title, index) => (
              <Button
                variant="outlined"
                fullWidth
                sx={{ mb: 1 }}
                disabled={activeSection === index}
                onClick={createHandleActiveSection(index)}
                key={title}
              >
                {t(title)}
              </Button>
            ))}
          </Box>

          <ServiceTitle active>{t(content[activeSection] ?? "")}</ServiceTitle>
          {/*<ServiceContent active>{t(`${content[activeSection]}Content`)}</ServiceContent>*/}

          {activeSection === 0 && (
            <PhotoGrid>
              {std.map((photo) => (
                <div key={photo.file}>
                  <Image
                    src={`/images/fos/std/${photo.file}`}
                    width={photo.direction === "portrait" ? 287 : 431}
                    height={photo.direction === "portrait" ? 431 : 287}
                  />
                </div>
              ))}
              {/*<div>*/}
              {/*  <Image src="/images/fos/mijn-klas-en-ik.jpg" width={287} height={431} />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <Image src="/images/fos/ik-als-leerling.jpg" width={287} height={431} />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <Image src="/images/fos/mijn-klas.jpg" width={287} height={431} />*/}
              {/*</div>*/}
            </PhotoGrid>
          )}

          {activeSection === 1 && (
            <Box sx={{ p: 3 }}>
              <Image src="/images/fos/kalenders.jpg" width={594} height={891} />
            </Box>
          )}

          {activeSection === 2 && (
            <PhotoGrid>
              {ditl.map((photo) => (
                <div key={photo.file}>
                  <Image
                    src={`/images/fos/ditl/${photo.file}`}
                    width={photo.direction === "portrait" ? 287 : 431}
                    height={photo.direction === "portrait" ? 431 : 287}
                  />
                </div>
              ))}
              {/*<div>*/}
              {/*  <Image src="/images/fos/ditl-0.jpeg" width={431} height={287} />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <Image src="/images/fos/ditl-1.jpeg" width={431} height={287} />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <Image src="/images/fos/ditl-2.jpeg" width={431} height={287} />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <Image src="/images/fos/ditl-3.jpeg" width={431} height={287} />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <Image src="/images/fos/ditl-4.jpeg" width={431} height={287} />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <Image src="/images/fos/ditl-5.jpeg" width={431} height={287} />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <Image src="/images/fos/ditl-6.jpeg" width={431} height={287} />*/}
              {/*</div>*/}
            </PhotoGrid>
          )}

          {activeSection === 3 && (
            <Box sx={{ mt: 2 }}>
              <ResponsiveYTVideo
                src="https://www.youtube.com/embed/gvHfSix823A"
                title="Blik Beatz"
              />
            </Box>
          )}
          {activeSection === 4 && (
            <div style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
              <Image src="/images/fos/platform.jpg" width={702} height={363} />
            </div>
          )}

          <Box textAlign="right">
            <ButtonGroup variant="text" disableElevation size="large">
              <Button onClick={handleClickPrev}>
                <ChevronLeftIcon />
              </Button>
              <Button onClick={handleClickNext}>
                <ChevronRigthIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </>
      ) : (
        <Grid container spacing={isMediumScreen ? 2 : 8}>
          <Grid item xs={12} sm={6}>
            <ServiceTitle active={activeSection === 0} onClick={createHandleActiveSection(0)}>
              {t("homeSectionServicePhotos")}
            </ServiceTitle>
            {/*<ServiceContent active={activeSection === 0}>*/}
            {/*  {t("homeSectionServicePhotosContent")}*/}
            {/*</ServiceContent>*/}

            <ServiceTitle active={activeSection === 1} onClick={createHandleActiveSection(1)}>
              {t("homeSectionServiceCalendar")}
            </ServiceTitle>
            {/*<ServiceContent active={activeSection === 1}>*/}
            {/*  {t("homeSectionServiceCalendarContent")}*/}
            {/*</ServiceContent>*/}

            <ServiceTitle active={activeSection === 2} onClick={createHandleActiveSection(2)}>
              {t("homeSectionServiceDayAtSchool")}
            </ServiceTitle>
            {/*<ServiceContent active={activeSection === 2}>*/}
            {/*  {t("homeSectionServiceDayAtSchoolContent")}*/}
            {/*</ServiceContent>*/}

            <ServiceTitle active={activeSection === 3} onClick={createHandleActiveSection(3)}>
              {t("homeSectionServiceVideo")}
            </ServiceTitle>
            {/*<ServiceContent active={activeSection === 3}>*/}
            {/*  {t("homeSectionServiceVideoContent")}*/}
            {/*</ServiceContent>*/}

            <ServiceTitle active={activeSection === 4} onClick={createHandleActiveSection(4)}>
              {t("homeSectionServicePlatform")}
            </ServiceTitle>
            {/*<ServiceContent active={activeSection === 4}>*/}
            {/*  {t("homeSectionServicePlatformContent")}*/}
            {/*</ServiceContent>*/}
          </Grid>
          <Grid item xs={12} sm={6}>
            {activeSection === 0 && (
              <PhotoGrid>
                {std.map((photo) => (
                  <div key={photo.file}>
                    <Image
                      src={`/images/fos/std/${photo.file}`}
                      width={photo.direction === "portrait" ? 287 : 431}
                      height={photo.direction === "portrait" ? 431 : 287}
                    />
                  </div>
                ))}
                {/*<div>*/}
                {/*  <Image src="/images/fos/ik-op-school.jpg" width={287} height={431} />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <Image src="/images/fos/mijn-klas-en-ik.jpg" width={287} height={431} />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <Image src="/images/fos/ik-als-leerling.jpg" width={287} height={431} />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <Image src="/images/fos/mijn-klas.jpg" width={287} height={431} />*/}
                {/*</div>*/}
              </PhotoGrid>
            )}

            {activeSection === 1 && (
              <div>
                <Image src="/images/fos/kalenders.jpg" width={594} height={891} />
              </div>
            )}

            {activeSection === 2 && (
              <PhotoGrid>
                {ditl.map((photo) => (
                  <div key={photo.file}>
                    <Image
                      src={`/images/fos/ditl/${photo.file}`}
                      width={photo.direction === "portrait" ? 287 : 431}
                      height={photo.direction === "portrait" ? 431 : 287}
                    />
                  </div>
                ))}
                {/*<div>*/}
                {/*  <Image src="/images/fos/ditl-0.jpeg" width={431} height={287} />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <Image src="/images/fos/ditl-1.jpeg" width={431} height={287} />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <Image src="/images/fos/ditl-2.jpeg" width={431} height={287} />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <Image src="/images/fos/ditl-3.jpeg" width={431} height={287} />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <Image src="/images/fos/ditl-4.jpeg" width={431} height={287} />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <Image src="/images/fos/ditl-5.jpeg" width={431} height={287} />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <Image src="/images/fos/ditl-6.jpeg" width={431} height={287} />*/}
                {/*</div>*/}
              </PhotoGrid>
            )}

            {activeSection === 3 && (
              <ResponsiveYTVideo
                src="https://www.youtube.com/embed/gvHfSix823A"
                title="Blik Beatz"
              />
            )}

            {activeSection === 4 && (
              <div style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                <Image src="/images/fos/platform.jpg" width={702} height={363} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}
